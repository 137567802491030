import MobileDetect from 'mobile-detect';
import DownloadBadges from '~/components/common/DownloadBadges.vue';
export default {
  components: {
    DownloadBadges
  },
  data() {
    return {
      loaded: false
    };
  },
  head() {
    return {
      title: 'Download - EXTRA'
    };
  },
  computed: {},
  watch: {},
  mounted() {
    const {
      redirect
    } = this.$route.query;
    const platform = new MobileDetect(window.navigator.userAgent);
    let url;
    if (platform.os() === 'AndroidOS') {
      // url = 'https://go.extra.app/android'
      // url = 'market://details?id=app.extra.android'
      url = 'https://go.extra.app/android-embed';
    }
    if (['iOS', 'iPadOS'].includes(platform.os())) {
      url = 'https://go.extra.app/ios';
    }
    console.log({
      os: platform.os(),
      platform,
      url,
      redirect
    });
    if (redirect === 0 || redirect === '0' || redirect === 'false' || redirect === 'no') {
      return;
    }
    if (url) {
      window.open(url);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      // a.setAttribute('target', '_blank')
      a.click();
    }
  },
  methods: {
    onSignupClick() {
      this.$router.push({
        path: '/application',
        query: {
          device_tag: window.g_device_tag
        }
      });
    }
  }
};