var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    class: [{
      loaded: _vm.loaded
    }, 'sm-md:mt-0' /*, 'pt-32 sm-md:pt-20'*/]
  }, [_c('loading-overlay', {
    attrs: {
      "tracking": {
        class: 'app-download',
        subclass: 'page'
      }
    },
    model: {
      value: _vm.loaded,
      callback: function ($$v) {
        _vm.loaded = $$v;
      },
      expression: "loaded"
    }
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "slide-fade",
      "mode": "out-in"
    }
  }, [_c('section', {
    staticClass: "dash--section"
  }, [_c('div', {
    staticClass: "section--container"
  }, [_c('div', {
    staticClass: "container -mt-6 section--content"
  }, [_c('div', {
    staticClass: "flex flex-col items-center w-full"
  }, [_c('div', {
    staticClass: "max-w-4xl -mt-2 -mb-24 video-container sm-md:-mx-40"
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/download_hero.jpg"),
      "alt": "Mobile views of the Extra App"
    }
  })]), _vm._v(" "), _c('p', {
    staticClass: "mb-2 text-5xl font-medium leading-10 tracking-wide text-center fade-in-1 font-display sm-md:text-3xl"
  }, [_vm._v("\n\t\t\t\t\t\t\tGet the Extra App.\n\t\t\t\t\t\t")]), _vm._v(" "), _c('p', {
    staticClass: "mt-4 mb-4 text-xl font-normal leading-10 tracking-wider text-center uppercase fade-in-2 font-display sm-md:text-xl sm-md:leading-7 sm-md:mt-2 sm-md:mb-0 sm-md:px-8"
  }, [_vm._v("\n\t\t\t\t\t\t\tIt’s going to come in handy\n\t\t\t\t\t\t")]), _vm._v(" "), _c('div', {
    staticClass: "mt-5 mb-12"
  }, [_c('download-badges')], 1), _vm._v(" "), _c('p', {
    staticClass: "mt-10 mb-2 text-3xl font-semibold leading-10 tracking-tight text-center fade-in-1 font-display sm-md:text-3xl"
  }, [_c('a', {
    on: {
      "click": _vm.onSignupClick
    }
  }, [_vm._v("\n\t\t\t\t\t\t\t\tNot a member yet? "), _c('span', {
    staticClass: "color-mint"
  }, [_vm._v("Sign up today")]), _vm._v(" "), _c('svg', {
    staticClass: "inline color-mint",
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5 12H19",
      "stroke": "currentColor",
      "stroke-width": "2.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M12 5L19 12L12 19",
      "stroke": "currentColor",
      "stroke-width": "2.5",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])])])]), _vm._v(" "), _c('links-footer', {
    staticClass: "justify-center fade-in-4"
  })], 1)])])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };