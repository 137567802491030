import QrcodeVue from 'qrcode.vue';
export default {
  components: {
    QrcodeVue
  },
  props: {
    downloadUrl: {
      type: String,
      default: 'https://extra.app/download-link'
    }
  },
  methods: {
    onAppStoreClick() {
      window.extra_data_ul.log([{
        app: 'sales-funnel',
        kingdom: 'engagement',
        phylum: 'button_press',
        class: 'app_store'
      }]);
    },
    onGooglePlayClick() {
      window.extra_data_ul.log([{
        app: 'sales-funnel',
        kingdom: 'engagement',
        phylum: 'button_press',
        class: 'google_play'
      }]);
    }
  }
};