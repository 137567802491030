var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "flex flex-col"
  }, [_c('div', {
    staticClass: "flex sm-md:flex-col sm-md:justify-center sm-md:items-center"
  }, [_c('a', {
    attrs: {
      "href": "http://go.extra.app/ios",
      "target": "blank"
    },
    on: {
      "click": _vm.onAppStoreClick
    }
  }, [_c('img', {
    staticClass: "h-16 mr-4 sm-md:mt-2 app-store-badge sm-md:mr-0 sm-md:w-48",
    attrs: {
      "src": require("assets/images/app_store_dark.svg"),
      "alt": "Download Extra App from the iOS App Store"
    }
  })]), _vm._v(" "), _c('div', [_c('a', {
    attrs: {
      "href": "http://go.extra.app/android-testing",
      "target": "blank"
    },
    on: {
      "click": _vm.onGooglePlayClick
    }
  }, [_c('img', {
    staticClass: "h-16 sm-md:mt-2 app-store-badge sm-md:w-48",
    attrs: {
      "src": require("assets/images/google_play_dark.svg"),
      "alt": "Download Extra App from the Google Play Store"
    }
  })])])]), _vm._v(" "), _c('qrcode-vue', {
    staticClass: "flex justify-center mt-8",
    attrs: {
      "value": _vm.downloadUrl,
      "size": "200",
      "background": "#000000",
      "foreground": "#ffffff"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };